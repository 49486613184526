<template>
  <div>
    <section class="hero is-light header">
      <div class="hero-body">
        <div class="container">
          <div class="header-title">My Account</div>
          <div class="header-subtitle">Your personal account information</div>
        </div>
      </div>
    </section>

    <section class="section mainsection">
      <div class="container">
        <div class="columns">
          <div class="column is-3">
            <UserMenu activeElement="settings" />
          </div>
          <div class="column">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Edit Contact Information
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <b-field grouped>
                    <b-field
                      label="Name"
                      :type="form.name.status"
                      :message="form.name.message"
                      expanded
                    >
                      <b-input
                        v-model="form.name.data"
                        placeholder="Your Full Name"
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Phone"
                      :type="form.phone.status"
                      :message="form.phone.message"
                      expanded
                    >
                      <b-input
                        v-model="form.phone.data"
                        placeholder="Your Phone Number"
                      ></b-input>
                    </b-field>

                  </b-field>

                  <!-- <b-field grouped>
                    <b-field
                      label="Email"
                      :type="form.email.status"
                      :message="form.email.message"
                      expanded                      
                    >
                      <b-input
                        v-model="form.email.data"
                        placeholder="Your Email Address"
                        disabled
                      ></b-input>
                    </b-field>

                  </b-field> -->

                  <b-field grouped>
                    <b-field
                      label="Street Address"
                      :type="form.address.status"
                      :message="form.address.message"
                      expanded
                    >
                      <b-input
                        v-model="form.address.data"
                        placeholder="Your Street Address"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Optional"
                      :type="form.optional.status"
                      :message="form.optional.message"
                      expanded
                    >
                      <b-input
                        v-model="form.optional.data"
                        placeholder="Sute, Unit, Building, Floor, etc."
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped>
                    <b-field
                      label="City"
                      :type="form.city.status"
                      :message="form.city.message"
                      expanded
                    >
                      <b-input v-model="form.city.data"></b-input>
                    </b-field>
                    <b-field
                      label="Postal / Zip Code"
                      :type="form.postal.status"
                      :message="form.postal.message"
                      expanded
                    >
                      <b-input v-model="form.postal.data"></b-input>
                    </b-field>
                  </b-field>

                  <b-field grouped>
                    <b-field
                      label="Province / State"
                      :type="form.state.status"
                      :message="form.state.message"
                      expanded
                    >
                      <b-input
                        v-model="form.state.data"
                        placeholder="Province, State, Region"
                      ></b-input>
                    </b-field>
                    <b-field
                      label="Country"
                      :type="form.country.status"
                      :message="form.country.message"
                      expanded
                    >
                      <b-input v-model="form.country.data"></b-input>
                    </b-field>
                  </b-field>
                  <b-message
                    auto-close
                    :duration="10000"
                    :active.sync="form.responce"
                    :type="form.status"
                  >
                    {{ form.message }}
                  </b-message>
                </div>
              </div>

              <footer class="card-footer">
                <span class="card-footer-item buttons card-foot">
                  <b-button
                    @click="restoreUserData()"
                    type="is-primary"
                    outlined
                    ><i class="fas fa-redo"></i> Restore</b-button
                  >
                  <b-button @click="saveUserData()" type="is-primary"
                    ><i class="far fa-save"></i> Save</b-button
                  >
                </span>
              </footer>
            </div>
          </div>
        </div>
        <!-- end main column -->
      </div>
    </section>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";

export default {
  name: "my-account",
  components: {
    UserMenu
  },
  metaInfo() {
    return {
      title: this.$mySite.title,
      titleTemplate: "My Account | %s",
      htmlAttrs: {
        lang: "en"
      },
      meta: [
        {
          name: "description",
          content: "Your personal account information"
        },
        {
          property: "og:title",
          content: this.$mySite.title,
          vmid: "og:title"
        },
        {
          property: "og:description",
          content: "Your personal account information",
          vmid: "og:description"
        },
        {
          property: "og:image",
          content: this.$mySite.image,
          vmid: "og:image"
        },
        {
          property: "og:url",
          content: this.$mySite.url,
          vmid: "og:url"
        }
      ]
    };
  }, //end meta
  data() {
    return {
      file: null,
      somevar: null,
      form: {
        name: {
          data: null,
          message: null,
          status: null
        },
        phone: {
          data: null,
          message: null,
          status: null
        },
        address: {
          data: null,
          message: null,
          status: null
        },
        optional: {
          data: null,
          message: null,
          status: null
        },
        city: {
          data: null,
          message: null,
          status: null
        },
        postal: {
          data: null,
          message: null,
          status: null
        },
        state: {
          data: null,
          message: null,
          status: null
        },
        country: {
          data: null,
          message: null,
          status: null
        },       
        responce: false,
        message: null,
        status: null
      },
      accountlInfo: null
    };
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    getUserData() {
      //begin post --------------------------------------------
      this.axios
        .post("user/manage_user.php", {
          token: this.$store.getters.IS_JWT,
          action: "getUser"
        })
        .then(response => {
          console.log(response.data);
          if (response.data.success) {
            this.accountlInfo = response.data.user;
            (this.form.name.data = response.data.user.name),             
              (this.form.address.data = response.data.user.address),
              (this.form.optional.data = response.data.user.optional),
              (this.form.phone.data = response.data.user.phone),
              (this.form.city.data = response.data.user.city),
              (this.form.state.data = response.data.user.state),
              (this.form.country.data = response.data.user.country),
              (this.form.postal.data = response.data.user.postal);
          } else {
            console.log("some error!");
          }
        })
        .catch(error => {
          console.log(error);
        });
      //end post-------------------------------------------------
    },
    restoreUserData() {
      (this.form.name.data = this.accountlInfo.name),
        (this.form.address.data = this.accountlInfo.address),
        (this.form.address.data = this.accountlInfo.address),
        (this.form.optional.data = this.accountlInfo.optional),
        (this.form.phone.data = this.accountlInfo.phone),
        (this.form.postal.data = this.accountlInfo.postal),
        (this.form.city.data = this.accountlInfo.city),
        (this.form.state.data = this.accountlInfo.state),
        (this.form.country.data = this.accountlInfo.country);
    },
    saveUserData() {
      if (
        this.formCheck("name") &
        this.formCheck("address") &
        this.formCheck("phone") &
        this.formCheck("postal") &
        this.formCheck("city") &
        this.formCheck("state") &
        this.formCheck("country")
      ) {
        console.log("checked!");
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("user/manage_user.php", {
            token: this.$store.getters.IS_JWT,
            name: this.form.name.data,
            phone: this.form.phone.data,
            address: this.form.address.data,
            optional: this.form.optional.data,
            city: this.form.city.data,
            state: this.form.state.data,
            country: this.form.country.data,
            postal: this.form.postal.data,
            action: "updateUser"
          })
          .then(response => {
            this.isLoading = false;
            this.form.responce = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              console.log(error);
            } else {
              this.form.status = "is-danger";
              console.log(error);
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("error!");
        //intensifies
      }
    },
    // emailCheck(e) {
    //   let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //   if (this.form[e].data === "" || !re.test(this.form[e].data)) {
    //     this.form[e].message = "Please enter a valid Email";
    //     this.form[e].status = "is-danger";
    //     return false;
    //   } else {
    //     this.form[e].message = null;
    //     this.form[e].status = "is-success";
    //     return true;
    //   }
    // },
    // passCheck(e) {
    //   if (this.form[e].data === "") {
    //     this.form[e].message = "Please enter a Password";
    //     this.form[e].status = "is-danger";
    //     return false;
    //   } else {
    //     this.form[e].message = null;
    //     this.form[e].status = "is-success";
    //     return true;
    //   }
    // },
    formCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "This field is required";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    }
  },
  mounted() {
    this.getUserData();
  }
};
</script>

<style scoped>
.card-foot {
  justify-content: flex-end; /* space-between flex-end; */
}
</style>
